<template>
  <ErrorBoundary name="CurrentPlan">
    <div :class="props.config == 'page' ? ['lg:w-[25rem]', 'mx-auto'] : []">
      <StateMachine
        :components="components"
        :routes="routes"
        :initial-data="initialData"
        client-only
        :id="id"
      />
    </div>
  </ErrorBoundary>
</template>

<script lang="ts" setup>
  import { SessionStore } from '~/stores/session'
  import { ProfileStore } from '~/stores/profile'
  import StateMachine from '~/components/StateMachine.vue'
  import type { IStateMachineRoutes } from '~/models/StateMachineModels'
  import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import ErrorBoundary from '~/components/errors/ErrorBoundary.vue'

  export type Config = 'sidebar' | 'page'

  const profile = ProfileStore.use()
  const session = SessionStore.use()

  const emit = defineEmits(['close'])

  const props = defineProps<{
    config: Config
    originalQuote: QuotedPlanVm
    id: string
  }>()

  const { originalQuote } = toRefs(props)

  const initialData = computed(() =>
    originalQuote.value
      ? [
          {
            key: 'originalQuote',
            value: originalQuote.value
          }
        ]
      : null
  )

  const components = {
    compare: defineAsyncComponent(
      () => import('~/components/currentPlan/components/CurrentPlanCompare.vue')
    ),
    carrierSearch: defineAsyncComponent(
      () => import('~/components/currentPlan/components/CurrentPlanCarrierSearch.vue')
    ),
    planSelect: defineAsyncComponent(
      () => import('~/components/currentPlan/components/CurrentPlanSelect.vue')
    )
  }

  // create union type of all component keys
  export type CurrentPlanComponents = keyof typeof components

  export interface CurrentPlanStateMachineRoutes extends IStateMachineRoutes {
    initial: CurrentPlanComponents
    components: Partial<{ [key in CurrentPlanComponents]: { next: CurrentPlanComponents } }>
  }

  const routes = computed(
    () =>
      ({
        initial: 'compare',
        components: {
          compare: { next: 'carrierSearch' },
          carrierSearch: { next: 'planSelect' },
          planSelect: { next: 'compare' }
        }
      } as CurrentPlanStateMachineRoutes)
  )
</script>

<style scoped></style>
